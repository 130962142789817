/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
// window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i);
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default));

// Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

// const app = new Vue({
//     el: '#app',
// });
import 'jquery-ui/ui/widgets/datepicker';
import 'daterangepicker/daterangepicker';
import 'lity/dist/lity.js';

require('simplelightbox/dist/simple-lightbox.jquery');

$(document).ready(function () {

  $('.lightbox a').simpleLightbox();

  var $grid = $('.masonry').imagesLoaded(function () {
    $grid.masonry({
      itemSelector: '.grid-item',
      percentPosition: true,
      columnWidth: '.grid-sizer'
    });
  });

});

/**
 *  Datatables padrao
 **/
(function ($, dataTable) {

  // Datatable global configuration
  $.extend(true, dataTable.defaults, {
    processing: true,
    serverSide: true,
    stateSave: true,
    deferRender: true,
    scrollX: true,
    preDrawCallback: function (settings) { $(".dataTables_processing").show(); return true; },
    drawCallback: function (settings) { $(".dataTables_processing").hide(); },
    language: {
      "emptyTable": "Nenhum registro encontrado",
      "info": "Mostrando de _START_ até _END_ de _TOTAL_ registros",
      "infoEmpty": "Mostrando 0 até 0 de 0 registros",
      "infoFiltered": "(Filtrados de _MAX_ registros)",
      "infoThousands": ".",
      "loadingRecords": "Carregando...",
      "processing": "Processando...",
      "zeroRecords": "Nenhum registro encontrado",
      "search": "Pesquisar",
      "paginate": {
        "next": "Próximo",
        "previous": "Anterior",
        "first": "Primeiro",
        "last": "Último"
      },
      "aria": {
        "sortAscending": ": Ordenar colunas de forma ascendente",
        "sortDescending": ": Ordenar colunas de forma descendente"
      },
      "select": {
        "rows": {
          "_": "Selecionado %d linhas",
          "1": "Selecionado 1 linha"
        },
        "cells": {
          "1": "1 célula selecionada",
          "_": "%d células selecionadas"
        },
        "columns": {
          "1": "1 coluna selecionada",
          "_": "%d colunas selecionadas"
        }
      },
      "buttons": {
        "copySuccess": {
          "1": "Uma linha copiada com sucesso",
          "_": "%d linhas copiadas com sucesso"
        },
        "collection": "Coleção  <span class=\"ui-button-icon-primary ui-icon ui-icon-triangle-1-s\"><\/span>",
        "colvis": "Visibilidade da Coluna",
        "colvisRestore": "Restaurar Visibilidade",
        "copy": "Copiar",
        "copyKeys": "Pressione ctrl ou u2318 + C para copiar os dados da tabela para a área de transferência do sistema. Para cancelar, clique nesta mensagem ou pressione Esc..",
        "copyTitle": "Copiar para a Área de Transferência",
        "csv": "CSV",
        "excel": "Excel",
        "pageLength": {
          "-1": "Mostrar todos os registros",
          "_": "Mostrar %d registros"
        },
        "pdf": "PDF",
        "print": "Imprimir"
      },
      "autoFill": {
        "cancel": "Cancelar",
        "fill": "Preencher todas as células com",
        "fillHorizontal": "Preencher células horizontalmente",
        "fillVertical": "Preencher células verticalmente"
      },
      "lengthMenu": "Exibir _MENU_ resultados por página",
      "searchBuilder": {
        "add": "Adicionar Condição",
        "button": {
          "0": "Construtor de Pesquisa",
          "_": "Construtor de Pesquisa (%d)"
        },
        "clearAll": "Limpar Tudo",
        "condition": "Condição",
        "conditions": {
          "date": {
            "after": "Depois",
            "before": "Antes",
            "between": "Entre",
            "empty": "Vazio",
            "equals": "Igual",
            "not": "Não",
            "notBetween": "Não Entre",
            "notEmpty": "Não Vazio"
          },
          "number": {
            "between": "Entre",
            "empty": "Vazio",
            "equals": "Igual",
            "gt": "Maior Que",
            "gte": "Maior ou Igual a",
            "lt": "Menor Que",
            "lte": "Menor ou Igual a",
            "not": "Não",
            "notBetween": "Não Entre",
            "notEmpty": "Não Vazio"
          },
          "string": {
            "contains": "Contém",
            "empty": "Vazio",
            "endsWith": "Termina Com",
            "equals": "Igual",
            "not": "Não",
            "notEmpty": "Não Vazio",
            "startsWith": "Começa Com"
          },
          "array": {
            "contains": "Contém",
            "empty": "Vazio",
            "equals": "Igual à",
            "not": "Não",
            "notEmpty": "Não vazio",
            "without": "Não possui"
          }
        },
        "data": "Data",
        "deleteTitle": "Excluir regra de filtragem",
        "logicAnd": "E",
        "logicOr": "Ou",
        "title": {
          "0": "Construtor de Pesquisa",
          "_": "Construtor de Pesquisa (%d)"
        },
        "value": "Valor",
        "leftTitle": "Critérios Externos",
        "rightTitle": "Critérios Internos"
      },
      "searchPanes": {
        "clearMessage": "Limpar Tudo",
        "collapse": {
          "0": "Painéis de Pesquisa",
          "_": "Painéis de Pesquisa (%d)"
        },
        "count": "{total}",
        "countFiltered": "{shown} ({total})",
        "emptyPanes": "Nenhum Painel de Pesquisa",
        "loadMessage": "Carregando Painéis de Pesquisa...",
        "title": "Filtros Ativos"
      },
      "thousands": ".",
      "datetime": {
        "previous": "Anterior",
        "next": "Próximo",
        "hours": "Hora",
        "minutes": "Minuto",
        "seconds": "Segundo",
        "amPm": [
          "am",
          "pm"
        ],
        "unknown": "-",
        "months": {
          "0": "Janeiro",
          "1": "Fevereiro",
          "10": "Novembro",
          "11": "Dezembro",
          "2": "Março",
          "3": "Abril",
          "4": "Maio",
          "5": "Junho",
          "6": "Julho",
          "7": "Agosto",
          "8": "Setembro",
          "9": "Outubro"
        },
        "weekdays": [
          "Domingo",
          "Segunda-feira",
          "Terça-feira",
          "Quarta-feira",
          "Quinte-feira",
          "Sexta-feira",
          "Sábado"
        ]
      },
      "editor": {
        "close": "Fechar",
        "create": {
          "button": "Novo",
          "submit": "Criar",
          "title": "Criar novo registro"
        },
        "edit": {
          "button": "Editar",
          "submit": "Atualizar",
          "title": "Editar registro"
        },
        "error": {
          "system": "Ocorreu um erro no sistema (<a target=\"\\\" rel=\"nofollow\" href=\"\\\">Mais informações<\/a>)."
        },
        "multi": {
          "noMulti": "Essa entrada pode ser editada individualmente, mas não como parte do grupo",
          "restore": "Desfazer alterações",
          "title": "Multiplos valores",
          "info": "Os itens selecionados contêm valores diferentes para esta entrada. Para editar e definir todos os itens para esta entrada com o mesmo valor, clique ou toque aqui, caso contrário, eles manterão seus valores individuais."
        },
        "remove": {
          "button": "Remover",
          "confirm": {
            "_": "Tem certeza que quer deletar %d linhas?",
            "1": "Tem certeza que quer deletar 1 linha?"
          },
          "submit": "Remover",
          "title": "Remover registro"
        }
      },
      "decimal": ","
    },
    aLengthMenu: [20, 50, 100, 200],
    iDisplayLength: 20,
    initComplete: function (settings, json) {
      var api = this.api();
      var textBox = $('.dataTables_filter label input');
      textBox.unbind();
      textBox.bind('keyup input', function (e) {
        if (e.keyCode == 8 && !textBox.val() || e.keyCode == 46 && !textBox.val()) {
          // do nothing ¯\_(ツ)_/¯
        } else if (e.keyCode == 13 || !textBox.val()) {
          api.search(this.value).draw();
        }
      });
    }
  });

})(jQuery, jQuery.fn.dataTable);

$(function () {

  $.datetimepicker.setLocale('pt-BR');
  $('.datetimepicker').datetimepicker({
    format: 'd/m/Y H:i:s'
  });

  /**
   * Remove acentos na busca do DataTable
   */
  var searchType = jQuery.fn.DataTable.ext.type.search;
  searchType.string = function (data) {
    return !data ?
      '' :
      typeof data === 'string' ?
        removeAccents(data) :
        data;
  };
  searchType.html = function (data) {
    return !data ?
      '' :
      typeof data === 'string' ?
        removeAccents(data.replace(/<.*?>/g, '')) :
        data;
  };

  function removeAccents(data) {
    return data
      .replace(/έ/g, 'ε')
      .replace(/[ύϋΰ]/g, 'υ')
      .replace(/ό/g, 'ο')
      .replace(/ώ/g, 'ω')
      .replace(/ά/g, 'α')
      .replace(/[ίϊΐ]/g, 'ι')
      .replace(/ή/g, 'η')
      .replace(/\n/g, ' ')
      .replace(/á/g, 'a')
      .replace(/é/g, 'e')
      .replace(/í/g, 'i')
      .replace(/ó/g, 'o')
      .replace(/ú/g, 'u')
      .replace(/ê/g, 'e')
      .replace(/î/g, 'i')
      .replace(/ô/g, 'o')
      .replace(/è/g, 'e')
      .replace(/ï/g, 'i')
      .replace(/ü/g, 'u')
      .replace(/ã/g, 'a')
      .replace(/õ/g, 'o')
      .replace(/ç/g, 'c')
      .replace(/ì/g, 'i');
  }

  $('#password').on('change', function () {
    password_confirmation();
  });
  
  $('#password_confirmation').on('blur', function () {
    password_confirmation();
  });

  $('#password_confirmation').on('change', function () {
    password_confirmation();
  });

  function password_confirmation() {
    if ($('#password').val() != $('#password_confirmation').val() && $('#password_confirmation').val()) {
      if ($('#password').val() != '') {
        $('#pwd_error').html('Os campos não estão idênticos.');
        $('#pwd_error').addClass('d-block');
        $('#password').addClass('is-invalid');
        $('#password_confirmation').addClass('is-invalid');
        return false;
      }
    }
    $('#pwd_error').html('');
    $('#pwd_error').removeClass('d-block');
    $('#password').removeClass('is-invalid');
    $('#password_confirmation').removeClass('is-invalid');
    return true;
  }

  /** Datepicker */
  $.datepicker.regional['pt_BR'] = {
    closeText: 'Fechar',
    prevText: '&#x3c;Anterior',
    nextText: 'Pr&oacute;ximo&#x3e;',
    currentText: 'Hoje',
    monthNames: ['Janeiro', 'Fevereiro', 'Mar&ccedil;o', 'Abril', 'Maio', 'Junho',
      'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
      'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    dayNames: ['Domingo', 'Segunda-feira', 'Ter&ccedil;a-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sabado'],
    dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'],
    weekHeader: 'Sm',
    dateFormat: 'dd/mm/yy',
    firstDay: 0,
    isRTL: false,
    showMonthAfterYear: false,
    yearSuffix: ''
  };
  $.datepicker.setDefaults($.datepicker.regional['pt_BR']);
  $('.datepicker').datepicker();

  $("#txtdate").datepicker({
    showOn: "button",
    buttonText: "Select date"
  });

  /** Rangepicker */
  $('.rangepicker').daterangepicker({
    "autoUpdateInput": false,
    "autoApply": true,
    "locale": {
      "format": "DD/MM/YYYY",
      "separator": " - ",
      "applyLabel": "Aplicar",
      "cancelLabel": "Cancelar",
      "daysOfWeek": [
        "Dom",
        "Seg",
        "Ter",
        "Qua",
        "Qui",
        "Sex",
        "Sab"
      ],
      "monthNames": [
        "Janeiro",
        "Fevereiro",
        "Março",
        "Abril",
        "Maio",
        "Junho",
        "Julho",
        "Agosto",
        "Setembro",
        "Outubro",
        "Novembro",
        "Dezembro"
      ]
    }
  }, function (start_date, end_date) {
    this.element.val(start_date.format("DD/MM/YYYY") + ' - ' + end_date.format("DD/MM/YYYY"));
  });

  /**
   * Preenchimento automático de formulários com consulta de CEP
   */
  //Quando o campo cep perde o foco.
  $("#postcode").blur(function () {

    //Nova variável "cep" somente com dígitos.
    var cep = $(this).val().replace(/\D/g, '');

    //Verifica se campo cep possui valor informado.
    if (cep != "") {

      //Expressão regular para validar o CEP.
      var validacep = /^[0-9]{8}$/;

      //Valida o formato do CEP.
      if (validacep.test(cep)) {

        //Preenche os campos com "..." enquanto consulta webservice.
        $("#street").val("...");
        $("#number").val("...");
        $("#district").val("...");
        $("#complemet").val("...");
        $("#city").val("...");

        //Consulta o webservice viacep.com.br/
        $.getJSON("https://viacep.com.br/ws/" + cep + "/json/?callback=?", function (dados) {

          if (!("erro" in dados)) {
            //Atualiza os campos com os valores da consulta.
            $("#street").val(dados.logradouro);
            $("#number").val('');
            $("#district").val(dados.bairro);
            $("#complement").val('');
            $("#city").val(dados.localidade);
            $("select#state").val(dados.uf);
          } //end if.
          else {
            //CEP pesquisado não foi encontrado.
            clear_form_postcode();
            // console.log("CEP não encontrado.");
          }
        });
      } //end if.
      else {
        //cep é inválido.
        clear_form_postcode();
        // console.log("Formato de CEP inválido.");
      }
    } //end if.
    else {
      //cep sem valor, limpa formulário.
      clear_form_postcode();
    }
  });

  /**
   * Função de máscara para telefones com DDD
   * @param val
   * @returns {string}
   */
  var maskBehavior = function (val) {
    return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
  },
    options = {
      onKeyPress: function (val, e, field, options) {
        field.mask(maskBehavior.apply({}, arguments), options);
      }
    };

  /**
   * Máscaras para preenchimento de formulários mais comuns
   */
  $('.mask-date').mask('99/99/9999');
  $('.mask-time').mask('99:99:99');
  $('.mask-date_time').mask('99/99/9999 99:99:99');
  $('.mask-cpf').mask('000.000.000-00', { reverse: true });
  $('.mask-cnpj').mask('00.000.000/0000-00', { reverse: true });
  $('.mask-money').mask('###.###.###,##', { reverse: true });
  $('.mask-postcode').mask('99999-999');
  $('.mask-birth').mask('99/99');
  $('.mask-cna').mask('SS 99999999');
  $('.mask-phone').mask(maskBehavior, options);
  $('.mask-number').mask('############################0');

  /** Função de mascara do password */
  $('#password_confirmation.mask-password').each(function (input) {
    $(this).after('<small id="pwd_error" class="text-danger italic"></small>');
  })

  $('.mask-password').each(function (input) {
    var id = $(this).attr('id');
    var size = ($(this).hasClass('form-control-sm'))? 'eye-sm' : '';
    $(this).after('<div id="' + id + '-eye" data-id="' + id + '" class="position-relative"><i class="fas text-graphite position-absolute fa-eye ' + size + '" data-view="0" data-id="' + id + '" onclick="clickeye($(this));"></i></div>');
  })

  window.clickeye = function (esse) {

    var id = $(esse).data('id');

    if ($(esse).data('view') == '0') {
      $(esse).removeClass('fa-eye');
      $(esse).addClass('fa-eye-slash');
      $(esse).data('view', '1');
    } else {
      $(esse).removeClass('fa-eye-slash');
      $(esse).addClass('fa-eye');
      $(esse).data('view', '0');
    }

    $('#' + id + '-eye').click(function () {
      if ($(esse).data('view') == '1') {
        $('#' + id).attr('type', 'text');
      } else {
        $('#' + id).attr('type', 'password');
      }
    });
  }

  /**
   * Função para limpar o formulário com cep em branco
   */
  var clear_form_postcode = function () {
    $("#street").val("");
    $("#number").val("");
    $("#district").val("");
    $("#complement").val("");
    $("#city").val("");
    $("#state").val("");
  }

  /**
   * Select2 listas
   */
  $('.cities-list').select2({
    ajax: {
      url: '/listas/cidades',
      dataType: 'json',
      data: function (params) {
        var query = {
          q: params.term,
          c: $(this).find('option:selected').val()
        }
        return query;
      }
    }
  });

  $('.users-list').select2({
    ajax: {
      url: '/listas/usuarios',
      dataType: 'json',
      data: function (params) {
        var query = {
          q: params.term,
          c: $(this).find('option:selected').val()
        }
        return query;
      }
    }
  });

});

//Função para formatar o valor em moeda
function formatMoney(amount, decimalCount = 2, decimal = ".", thousands = ",") {
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
    let j = (i.length > 3) ? i.length % 3 : 0;

    return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i).toFixed(decimalCount).slice(2) : "");
  } catch (e) {
    // console.log(e)
  }
};

window.formatYMD = function (date) {
  return date.toISOString().substring(0, 10);
}

window.formatDateExt = function (date) {

  var extenso;
  date = new Date(date);

  var dayWeek = ["Domingo", "Segunda-feira", "Terça-feira", "Quarta-feira", "Quinta-feira", "Sexta-feira", "Sábado"][date.getDay()];
  var day = date.getDate();
  var month = ["janeiro", "fevereiro", "março", "abril", "maio", "junho", "julho", "agosto", "setembro", "outubro", "novembro", "dezembro"][date.getMonth()];
  var year = date.getFullYear();

  return `${dayWeek}, ${day} de ${month} de ${year}`;
}