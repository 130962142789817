require('./app');
window.Swal = require('sweetalert2');
require('owl.carousel');

$(document).ready(function () {

    $('#container').imagesLoaded();

    $('.owl-carousel').owlCarousel({
        loop: false,
        margin: 30,
        nav: false,
        dots: true,
        responsive: {
            0: {
                items: 1
            },
            600: {
                items: 2
            },
            1000: {
                items: 5,
                mouseDrag: false
            }
        }
    });

    $('[data-toggle="popover"]').popover()
});

// Accessibility increase / decrease font-size (call in button on accessibility bar )
var fontSize = 0;
function increaseFontSize() {
    fontSize < 10 &&
        ($.each($("p, span, a, h1, h2, h3, h4, h5, h6, li, label, small"), function (t, e) {
            var a = parseInt($(this).css("font-size"));
            (a = a + 1 + "px"),
                $(this).attr("style", "font-size:" + a + " !important");
        }),
            (fontSize += 1));
}

function decreaseFontSize() {
    -2 < fontSize &&
        ($.each($("p, span, a, h1, h2, h3, h4, h5, h6, li, label, small"), function (t, e) {
            var a = parseInt($(this).css("font-size"));
            (a = a - 1 + "px"),
                $(this).attr("style", "font-size:" + a + " !important");
        }),
            (fontSize -= 1));
}

$(document).ready(function () {

    $('#btn-increase').click(function () {
        increaseFontSize();
    });

    $('#btn-decrease').click(function () {
        decreaseFontSize();
    });

    $("#btn-contrast").click(function () {
        $("html").toggleClass("hc"),
            sessionStorage.setItem(
                "alto-contraste",
                String($("html").hasClass("hc"))
            );
    }), "true" == sessionStorage.getItem("alto-contraste") && $("html").addClass("hc");
});