window._ = require('lodash');

try {
    
    window.$ = window.jQuery = require('jquery');
    
    require('bootstrap');
    require('jquery-ui');
    require('jquery-mask-plugin');
    require('jquery-form');
    require('jquery-datetimepicker'); //Datetimepicker
    jQuery.datetimepicker.setLocale('pt_BR');
    require('datatables.net-bs4');
    require('sharer.js');
    require('lity');
    require('select2');
    $.fn.select2.amd.define('select2/i18n/pt-BR',[],require("select2/src/js/select2/i18n/pt-BR"));

    // CKEditor 5 builder online
    // https://ckeditor.com/ckeditor-5/online-builder/
    require('../ckeditor5/build/ckeditor');

} catch (e) {
}

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

 window.axios = require('axios');

 window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
 
 /**
  * Next we will register the CSRF Token as a common header with Axios so that
  * all outgoing HTTP requests automatically have it attached. This is just
  * a simple convenience so we don't have to attach every token manually.
  */
 
 let token = document.head.querySelector('meta[name="csrf-token"]');
 
 if (token) {
     window.axios.defaults.headers.common['X-CSRF-TOKEN'] = token.content;
 } else {
     console.error('CSRF token not found: https://laravel.com/docs/csrf#csrf-x-csrf-token');
 }
 
 /**
  * Echo exposes an expressive API for subscribing to channels and listening
  * for events that are broadcast by Laravel. Echo and event broadcasting
  * allows your team to easily build robust real-time web applications.
  */
 
 // import Echo from 'laravel-echo'
 
 // window.Pusher = require('pusher-js');
 
 // window.Echo = new Echo({
 //     broadcaster: 'pusher',
 //     key: process.env.MIX_PUSHER_APP_KEY,
 //     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
 //     encrypted: true
 // });
